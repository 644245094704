<template>
    <el-dialog
        :model-value="show"
        :before-close="onClose"
        :close-on-click-modal="false"
        :title="id ? '编辑序列号规则' : '新增序列号规则'"
        custom-class="common-edit"
        width="600px"
    >
        <el-form ref="dataFormRef" :label-width="100" :model="dataForm" :rules="dataRules" label-position="left">
            <el-form-item label="规则类型：" prop="type">
                <el-select v-model="dataForm.type" filterable placeholder="请选择规则类型">
                    <el-option
                        v-for="item in typeOptions"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="规则名称：" prop="name">
                <el-input v-model="dataForm.name" placeholder="请输入规则名称"></el-input>
            </el-form-item>
            <el-form-item label="规则名称：" prop="name2">
                <el-input v-model="dataForm.name2" placeholder="请输入规则名称"></el-input>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button @click="onClose">取消</el-button>
            <el-button type="primary" @click="onSubmit">提交</el-button>
        </template>
    </el-dialog>
</template>

<script setup>
import { ref, watch, nextTick } from "vue";
import { ADD_SERIAL_NUMBER_RULE, EDIT_SERIAL_NUMBER_RULE } from "@/api/serialNumber.js";
import { ElMessage } from "element-plus";

let props = defineProps({
    show: {
        type: Boolean,
        default: false
    },
    id: { type: [String, Number] },
    detail: { type: Object }
});

let emit = defineEmits(["update:show", "update"]);

watch(
    () => ({ id: props.id, show: props.show }),
    (newVal) => {
        if (newVal.show && newVal.id) getDetail();
    },
    { deep: true }
);

// 获取详情
let getDetail = async () => {
    await nextTick();
    dataForm.value = {
        type: props.detail.type,
        name: props.detail.name,
        name2: props.detail.name2
    };
};

let typeOptions = ref([
    { id: 1, name: "生产厂家" },
    { id: 2, name: "资产管理" }
]);

// 表单数据
let dataForm = ref({
    type: [],
    name: null,
    name2: null
});

let dataFormRef = ref();

let dataRules = {
    type: [{ required: true, message: "请选择规则类型！", trigger: "change" }],
    name: [{ required: true, message: "请输入规则名称！", trigger: "blur" }],
    name2: [{ required: true, message: "请输入规则名称！", trigger: "blur" }]
};

// 关闭
let onClose = () => {
    if (dataFormRef.value) dataFormRef.value.resetFields();
    emit("update");
    emit("update:show", false);
};

// 提交
let onSubmit = () => {
    dataFormRef.value.validate((valid) => {
        if (valid) {
            if (!props.id) {
                ADD_SERIAL_NUMBER_RULE({
                    ...dataForm.value
                }).then((res) => {
                    if (res.data.code === 200) {
                        onClose();
                        ElMessage.success("新增成功");
                    }
                });
            } else {
                EDIT_SERIAL_NUMBER_RULE({
                    ...dataForm.value,
                    id: props.id
                }).then((res) => {
                    if (res.data.code === 200) {
                        onClose();
                        ElMessage.success("编辑成功");
                    }
                });
            }
        }
    });
};
</script>
